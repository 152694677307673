import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import CheckIcon from '../icons/CheckIcon'

export interface MultiRowColumnChoiceOptionProps {
    checked?: boolean
    title: string
    description?: string // Made optional
    images: string[]
    onClick?: () => void
    style?: CSSProperties
}

export const MultiRowColumnChoiceOption: React.FC<MultiRowColumnChoiceOptionProps> = ({
    checked = false,
    title,
    description,
    images,
    style,
    onClick,
}) => {
    const wrapperStyles = classNames(
        'flex w-full px-4 py-3 mb-2 rounded-xl cursor-pointer animate-fade-in-left border-2',
        checked ? 'bg-[#E8FAF2] border-[#00C781]' : 'bg-white border-transparent',
    )

    const contentStyles = classNames('flex flex-col flex-grow')
    const titleStyles = classNames('text-left text-sm font-medium')
    const descriptionStyles = classNames('text-left text-sm text-gray-500 mb-2')
    const imagesContainerStyles = classNames('flex flex-wrap -mx-1')
    const imageStyles = classNames('w-10 h-10 m-1 object-cover rounded')
    const checkboxContainerStyles = classNames('flex items-center ml-4')
    const checkboxStyles = classNames(
        'w-4 h-4 flex justify-center items-center rounded-full',
        checked ? 'bg-[#00C781]' : 'bg-gray-200',
    )

    return (
        <div onClick={onClick} style={style} className={wrapperStyles}>
            <div className={contentStyles}>
                <div className={titleStyles}>{title}</div>
                {description && <div className={descriptionStyles}>{description}</div>}
                <div className={imagesContainerStyles}>
                    {images.map((image, index) => (
                        <img key={index} className={imageStyles} src={image} alt="" />
                    ))}
                </div>
            </div>
            <div className={checkboxContainerStyles}>
                <div className={checkboxStyles}>
                    {checked ? <CheckIcon className="w-5 h-5 text-white" /> : <div className="w-5 h-5" />}
                </div>
            </div>
        </div>
    )
}

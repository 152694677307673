import { Answers, Question, SurveyConfig } from '../../contexts/survey'
import dynamic from 'next/dynamic'
import assets from './assets'
import {
    validateHeight,
    validateTargetWeight,
    validateWeight,
    validateAge,
    validateName,
    validateEatingWindow,
} from '../../utils/validation'
import BodyHeightStep from './BodyHeightStep'
import AgeStep from './AgeStep'
import { SurveyExperiments, SurveyTheme } from '@contexts/survey/survey'
import LottieTextStep from './LottieTextStep'

const DownloadStep = dynamic(() => import('./DownloadStep'))
const PlanReadyStep = dynamic(() => import('./PlanReadyStep/PlanReadyStep'))
const RegistrationStep = dynamic(() => import('./RegistrationStep'))
const YourCurrentStateStep = dynamic(() => import('./v2/YourCurrentStateStep'))
const YourTargetStateStep = dynamic(() => import('./v2/YourTargetStateStep'))
const LosingWeightStep = dynamic(() => import('./v2/LosingWeightStep'))
const TestimonialsStep = dynamic(() => import('./v2/TestimonialsStep'))
const FoodScanTextStep = dynamic(() => import('./v2/FoodScanTextStep'))
const ComparisonStep = dynamic(() => import('./v2/ComparisonStep'))
const NutrionalSummary = dynamic(() => import('./v2/NutrionalSummary'))
const StorytellingWeightLoss = dynamic(() => import('./v2/StorytellingWeightLoss'))
const Reviews = dynamic(() => import('./v2/Reviews'))
const PreparePlanStep = dynamic(() => import('./v2/PreparePlanStep'))
const SignUpInsurance = dynamic(() => import('../insurance/InvoiceSignUp'))
const InsurancePaywall = dynamic(() => import('../insurance/InsurancePaywall'))
const LandingPage = dynamic(() => import('./LandingPage'))

const getSurveyConfigInsurance = (
    theme: SurveyTheme,
    funnel: string,
    experiements: SurveyExperiments,
    answers: Answers,
): SurveyConfig => {
    return {
        milestones: [0, 6, 12, 24, 45, 63],
        questions: [
            {
                name: 'landing',
                type: 'custom',
                path: 'landing',
                isRequired: true,
                hideButtonBar: true,
                hideStepper: true,
                component: <LandingPage />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.locale === 'de',
            },
            ...[
                {
                    name: 'goal',
                    type: 'select',
                    path: 'your_goal',
                    isRequired: true,
                    multiple: true,
                    imageOptions: [
                        {
                            label: 'lose_weight',
                            image: assets.goal_v2.lose_weight,
                        },
                        {
                            label: 'boost_health',
                            image: assets.goal_v2.boost_health,
                        },
                        {
                            label: 'healthy_eating',
                            image: assets.goal_v2.healthy_eating,
                        },
                        {
                            label: 'reduce_stress',
                            image: assets.goal_v2.reduce_stress,
                        },
                        {
                            label: 'live_longer',
                            image: assets.goal_v2.live_longer,
                        },
                    ],
                } as Question,
            ]
                .sort((a, b) => (a.name === experiements.firstQuestion ? -1 : 1))
                .filter(e => e),
            {
                name: 'about_diet',
                type: 'custom',
                path: 'regular_diet_vs_fastic',
                buttonNextTitle: 'common:controls.got_it',
                component: <LottieTextStep animation={assets.animations.fastic_vs_diet_chart} />,
            },
            {
                name: 'gender',
                type: 'select',
                path: 'gender',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'female', image: assets.gender_v2.female },
                    { label: 'male', image: assets.gender_v2.male },
                    { label: 'divers', image: assets.gender_v2.divers },
                ],
            } as Question,
            {
                name: 'age',
                type: 'custom',
                path: 'age',
                isRequired: true,
                component: <AgeStep />,
                //choices: ['18-29', '30-39', '40-49', '50-59', '60-69', '70+'],
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['age'] && !validateAge(answers['age'] as number)
                },
            },
            {
                name: 'body_height',
                type: 'custom',
                path: 'body_height',
                isRequired: true,
                component: <BodyHeightStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['height'] && !validateHeight(answers['height'] as number)
                },
            },
            {
                name: 'current_state',
                type: 'custom',
                path: 'your_current_state',
                isRequired: true,
                component: <YourCurrentStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['weight'] && !validateWeight(answers['weight'] as number)
                },
            },
            {
                name: 'target_state',
                type: 'custom',
                path: 'your_target_state',
                isRequired: true,
                component: <YourTargetStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return (
                        answers['target_weight'] &&
                        !validateTargetWeight(answers['target_weight'] as number, answers['weight'] as number)
                    )
                },
            },
            {
                name: 'losing_weight',
                type: 'custom',
                path: 'losing_weight_with_fastic',
                buttonNextTitle: 'common:controls.got_it',
                component: <LosingWeightStep animation={assets.animations.fasty_perfect_body} />,
            },
            {
                name: 'testimonials',
                type: 'custom',
                path: 'testimonials',
                hideBackHeader: true,
                component: <TestimonialsStep />,
            },
            {
                name: 'medical_conditions',
                type: 'select',
                path: 'any_medical_conditions',
                isRequired: true,
                multiple: true,
                imageOptions: [
                    { label: 'none', image: assets.medical_conditions.none },
                    { label: 'hypertension', image: assets.medical_conditions.hypertension },
                    { label: 'high_cholesterol', image: assets.medical_conditions.high_cholesterol },
                    { label: 'obesity', image: assets.medical_conditions.obesity },
                    { label: 'diabetes', image: assets.medical_conditions.diabetes },
                    { label: 'heart_disease', image: assets.medical_conditions.heart_disease },
                    { label: 'cancer', image: assets.medical_conditions.cancer },
                    { label: 'lung_disease', image: assets.medical_conditions.lung_disease },
                    { label: 'thyroid_disease', image: assets.medical_conditions.thyroid_disease },
                    { label: 'gastric_disease', image: assets.medical_conditions.gastric_disease },
                ],
                choiceGroups: [
                    ['none'],
                    [
                        'hypertension',
                        'high_cholesterol',
                        'obesity',
                        'diabetes',
                        'heart_disease',
                        'cancer',
                        'lung_disease',
                        'thyroid_disease',
                        'gastric_disease',
                    ],
                ],
            },
            {
                name: 'food_scanner',
                type: 'custom',
                path: 'food_scanner',
                component: <LottieTextStep animation={assets.animations.food_scan} reverseOrder={true} />,
            },
            {
                name: 'food_scanner_intro_1',
                type: 'custom',
                path: 'barcode_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'food_scanner_intro_2',
                type: 'custom',
                path: 'food_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'food_scanner_intro_3',
                type: 'custom',
                path: 'menu_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'comparison',
                type: 'custom',
                path: 'fastic_vs_diet',
                component: <ComparisonStep />,
            },
            {
                name: 'diet',
                type: 'select',
                path: 'any_specific_diet',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'no_diets', image: assets.diet_v2.no },
                    { label: 'keto', image: assets.diet_v2.keto },
                    { label: 'vegan', image: assets.diet_v2.vegan },
                    { label: 'vegetarian', image: assets.diet_v2.vegetarian },
                    { label: 'paleo', image: assets.diet_v2.paleo },
                    { label: 'climatarian', image: assets.diet_v2.climatarian },
                    { label: 'alkaline', image: assets.diet_v2.alkaline },
                    { label: 'pescatarian', image: assets.diet_v2.pescatarian },
                ],
            },
            {
                name: 'diet_restrictions',
                type: 'select',
                path: 'any_diet_restrictions',
                multiple: true,
                imageOptions: [
                    { label: 'none', image: assets.diet_restrictions.none },
                    { label: 'sugar_free', image: assets.diet_restrictions.sugar_free },
                    { label: 'lactose_free', image: assets.diet_restrictions.lactose_free },
                    { label: 'gluten_free', image: assets.diet_restrictions.gluten_free },
                    { label: 'nut_free', image: assets.diet_restrictions.nut_free },
                ],
                choiceGroups: [['none'], ['sugar_free', 'lactose_free', 'gluten_free', 'nut_free']],
            },
            {
                name: 'nutritional_summary',
                type: 'custom',
                path: 'nutritional_summary',
                component: <NutrionalSummary />,
            },
            {
                name: 'weight_loss_success',
                type: 'custom',
                path: 'weight_loss_success',
                component: <StorytellingWeightLoss />,
            },
            {
                name: 'reviews',
                type: 'custom',
                path: 'reviews',
                component: <Reviews />,
            },
            {
                name: 'prepare_plan',
                type: 'custom',
                path: 'preparing_your_plan',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PreparePlanStep />,
            },
            //Personal Health Plan: TO DO
            //REWORK DONE UNTIL HERE
            {
                name: 'program',
                type: 'custom',
                path: 'your_program',
                isRequired: true,
                hideHeader: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PlanReadyStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: has purchased
                    return false
                },
            },
            {
                name: 'insurance_paywall',
                type: 'custom',
                path: 'your_weightloss_plan',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <InsurancePaywall />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
            },
            {
                name: 'registration_insurance',
                type: 'custom',
                path: 'registration_insurance',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <SignUpInsurance />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'registration',
                type: 'custom',
                path: 'registration',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <RegistrationStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'download',
                type: 'custom',
                path: 'download',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <DownloadStep />,
            },
        ],
    }
}

export default getSurveyConfigInsurance

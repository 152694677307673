import React from 'react'
import { useTranslation } from '../../i18n'
import { Title } from './Title'
import { useSurvey } from '@contexts/survey'
import { SurveyStateInitialized } from '@contexts/survey/survey'

export interface StepProps {
    name: string
    title?: React.ReactElement
    subtitle?: React.ReactElement
}
export const Step: React.FC<React.PropsWithChildren<StepProps>> = ({
    name,
    title: customTitle,
    subtitle: customSubtitle,
    children,
    ...props
}) => {
    const { t, i18n } = useTranslation()
    const [surveyState, _] = useSurvey()

    const title =
        !customTitle && i18n.exists(`survey:${name}.title`)
            ? t(`survey:${name}.title`, { context: (surveyState as SurveyStateInitialized).theme })
            : null
    const subtitle =
        !customSubtitle && i18n.exists(`survey:${name}.subtitle`)
            ? t(`survey:${name}.subtitle`, {
                  context: (surveyState as SurveyStateInitialized).theme,
                  interpolation: { escapeValue: false },
              })
            : null

    return (
        <div className="max-w-xl w-full mx-auto px-4 " {...props}>
            {customTitle && customTitle}
            {title && !customTitle && <Title>{title}</Title>}
            {customSubtitle && customSubtitle}
            {subtitle && !customSubtitle && (
                <h3
                    className="text-center text-lg xs:leading-tight text-dark3 mt-4 px-2 xs:px-4"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
            )}
            <div className="mt-7">{children}</div>
        </div>
    )
}

import { Answers, Question, SurveyConfig } from '../../contexts/survey'
import dynamic from 'next/dynamic'
import assets from './assets'
import {
    validateEmail,
    validateHeight,
    validateTargetWeight,
    validateWeight,
    validateAge,
} from '../../utils/validation'
import BodyHeightStep from './BodyHeightStep'
import AgeStep from './AgeStep'
import { EmailStep } from './EmailStep/EmailStep'
import { SurveyExperiments, SurveyTheme, Funnel } from '@contexts/survey/survey'
import LottieTextStep from './LottieTextStep'

const BodyTypeStep = dynamic(() => import('./BodyTypeStep'))
const DietVsFasticStep = dynamic(() => import('./DietVsFasticStep'))
const DownloadStep = dynamic(() => import('./DownloadStep'))
const HealthyHabitsStepTitle = dynamic(() => import('./HealthyHabitsStepTitle'))
const PlanReadyStep = dynamic(() => import('./PlanReadyStep/PlanReadyStep'))
const PreparePlanStep = dynamic(() => import('./PreparePlanStep'))
const ProcessingStep = dynamic(() => import('./ProcessingStep'))
const RegistrationStep = dynamic(() => import('./RegistrationStep'))
const StatsStep = dynamic(() => import('./StatsStep'))
const StickingToPlanStep = dynamic(() => import('./StickingToPlanStep'))
const StorytellingStep = dynamic(() => import('./StorytellingStep'))
const TrustStep = dynamic(() => import('./TrustStep'))
const SocialProof = dynamic(() => import('./SocialProof'))
const TypicalLunchStepTitle = dynamic(() => import('./TypicalLunchStepTitle'))
const WaterStep = dynamic(() => import('./WaterStep'))
const YourCurrentStateStep = dynamic(() => import('./YourCurrentStateStep'))
const YourTargetStateStep = dynamic(() => import('./YourTargetStateStep'))
const SignUpInsurance = dynamic(() => import('../insurance/InvoiceSignUp'))
const InsurancePaywall = dynamic(() => import('../insurance/InsurancePaywall'))
const LandingPage = dynamic(() => import('./LandingPage'))

const isNumeric = (val: string): boolean => {
    return !isNaN(Number(val))
}

// This function is used to get the survey configuration based on the theme and experiments
const getSurveyConfig = (
    theme: SurveyTheme,
    funnel: Funnel,
    experiements: SurveyExperiments,
    answers: Answers,
): SurveyConfig => {
    return {
        milestones: [0, 6, 10, 24],
        questions: [
            {
                name: 'landing',
                type: 'custom',
                path: 'landing',
                isRequired: true,
                hideButtonBar: true,
                hideStepper: true,
                component: <LandingPage />,
                shouldRender: (currentAnswers: Answers): boolean =>
                    (funnel === 'no_loader' || funnel === 'no_loader_track') && currentAnswers.locale === 'de',
            },
            ...[
                {
                    name: 'goal',
                    type: 'select',
                    path: 'your_goal',
                    isRequired: true,
                    hideButtonBar: true,
                    completeOnSelect: true,
                    imageOptions: [
                        {
                            label: 'lose_weight',
                            image: assets.goal.lose_weight,
                        },
                        {
                            label: 'boost_health',
                            image: assets.goal.boost_health,
                        },
                        ...[
                            ['keto', 'tiktok', 'foodscanner'].includes(theme) && {
                                label: 'gain_muscles',
                                image: assets.goal.gain_muscles,
                            },
                        ].filter(e => e),
                    ],
                } as Question,
                ...(answers.gender
                    ? []
                    : [
                          {
                              name: 'gender',
                              type: 'select',
                              path: 'gender',
                              isRequired: true,
                              hideButtonBar: true,
                              completeOnSelect: true,
                              imageOptions: [
                                  { label: 'female', image: assets.gender.female },
                                  { label: 'male', image: assets.gender.male },
                              ],
                          } as Question,
                      ]),
            ]
                .sort((a, b) => (a.name === experiements.firstQuestion ? -1 : 1))
                .filter(e => e),

            {
                name: 'meals',
                type: 'select',
                path: 'number_of_meals',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: '1', image: assets.number_of_meals.ramen_1 },
                    { label: '2', image: assets.number_of_meals.ramen_2 },
                    { label: '3', image: assets.number_of_meals.ramen_3 },
                    { label: '4+', image: assets.number_of_meals.ramen_4 },
                ],
                validate: (question: Question, answers: Answers) => {
                    return Object.prototype.hasOwnProperty.call(answers, 'meals')
                },
            },
            /*{
                name: 'social_proof',
                type: 'custom',
                path: 'proof',
                isRequired: false,
                showNextHeader: true,
                component: <SocialProof />,
            },
            */
            {
                name: 'home_cooked_meals',
                type: 'select',
                path: 'how_much_home_cooked_meals',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: '1-2', image: assets.home_cooked_meals.pan_2 },
                    { label: '3-4', image: assets.home_cooked_meals.pan_3 },
                    { label: '5+', image: assets.home_cooked_meals.pan_5 },
                    { label: 'not_at_all', image: assets.home_cooked_meals.pan_1 },
                ],
                validate: (question: Question, answers: Answers) => {
                    return Object.prototype.hasOwnProperty.call(answers, 'home_cooked_meals')
                },
            },
            {
                name: 'diet',
                type: 'select',
                path: 'any_specific_diet',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'no_diets', image: assets.diet.no },
                    ...[theme !== 'keto' && { label: 'keto', image: assets.diet.keto }].filter(e => e),
                    { label: 'vegan', image: assets.diet.vegan },
                    { label: 'vegetarian', image: assets.diet.vegetarian },
                    { label: 'pescatarian', image: assets.diet.pescatarian },
                    { label: 'other', image: assets.diet.other },
                ],
            },
            {
                name: 'about_diet',
                type: 'custom',
                path: 'regular_diet_vs_fastic',
                buttonNextTitle: 'common:controls.got_it',
                component: <DietVsFasticStep />,
            },
            ...[
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'other_goals',
                        type: 'select',
                        path: 'other_goals',
                        isRequired: true,
                        multiple: true,
                        choices: [
                            'shape',
                            'more_confident',
                            'boost_activity',
                            'strengthen_immunity',
                            'improve_mental_clarity',
                        ],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'improve_health',
                        type: 'select',
                        path: 'improve_health',
                        isRequired: true,
                        multiple: true,
                        choices: [
                            'reduce_body_fat',
                            'enhance_digestion',
                            'better_sleep',
                            'cognitive_focus',
                            'manage_chronic_conditions',
                        ],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'plan_methods',
                        type: 'select',
                        path: 'plan_methods',
                        isRequired: true,
                        multiple: true,
                        choices: ['fasting', 'food_scanner', 'count_calories', 'calorie_deficit', 'keto_diet'],
                        preselect: ['fasting', 'food_scanner', 'count_calories'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'journey_begins',
                        type: 'custom',
                        path: 'journey_begins',
                        buttonNextTitle: 'common:controls.continue',
                        component: <LottieTextStep animation={assets.animations.fasty_journey_begins} />,
                    } as Question),
            ].filter(e => e),
            {
                name: 'sleep',
                type: 'select',
                path: 'how_do_you_sleep',
                showNextHeader: true,
                isRequired: true,
                choices: ['minimal', 'some', 'well', 'long'],
            },
            {
                name: 'water',
                type: 'custom',
                path: 'how_much_water_you_drink',
                isRequired: true,
                component: <WaterStep />,
                validate: (question: Question, answers: Answers) =>
                    isNumeric(answers[question.name] as string) && Number(answers[question.name]) > 0,
            },
            {
                name: 'work',
                type: 'select',
                path: 'whats_your_job_like',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'not_active', image: assets.work.sedentary },
                    { label: 'mid_active', image: assets.work.active },
                    { label: 'very_active', image: assets.work.v_active },
                ],
            },
            ...[
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'calorie_intake',
                        type: 'select',
                        path: 'calorie_intake',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: ['less_1500', 'between_1500_2000', 'between_2000_2500', 'more_2500', 'unknown'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'eating_habits',
                        type: 'select',
                        path: 'eating_habits',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: [
                            'balanced',
                            'mostly_healthy',
                            'needs_improvement',
                            'inconsistent',
                            'mostly_unhealthy',
                        ],
                    } as Question),
            ].filter(e => e),
            {
                name: 'trust_review',
                type: 'custom',
                path: 'sticking_to_plan',
                hideButtonBar: true,
                hideBackHeader: true,
                component: <StickingToPlanStep />,
            },
            {
                name: 'age',
                type: 'custom',
                path: 'age',
                isRequired: true,
                component: <AgeStep />,
                //choices: ['18-29', '30-39', '40-49', '50-59', '60-69', '70+'],
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['age'] && !validateAge(answers['age'] as number)
                },
            },
            {
                name: 'body_height',
                type: 'custom',
                path: 'body_height',
                isRequired: true,
                component: <BodyHeightStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['height'] && !validateHeight(answers['height'] as number)
                },
            },
            {
                name: 'current_state',
                type: 'custom',
                path: 'your_current_state',
                isRequired: true,
                component: <YourCurrentStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['weight'] && !validateWeight(answers['weight'] as number)
                },
            },
            {
                name: 'target_state',
                type: 'custom',
                path: 'your_target_state',
                isRequired: true,
                component: <YourTargetStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return (
                        answers['target_weight'] &&
                        !validateTargetWeight(answers['target_weight'] as number, answers['weight'] as number)
                    )
                },
            },
            ...[
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'special_occasion',
                        type: 'select',
                        path: 'special_occasion',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: ['vacation', 'wedding', 'birthday', 'summer', 'school_reunion', 'none'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'improve_health_story',
                        type: 'custom',
                        path: 'improve_health_story',
                        component: <LottieTextStep animation={assets.animations.fasty_improve_health_story} />,
                    } as Question),
            ].filter(e => e),
            {
                name: 'healthy_habits',
                type: 'select',
                path: 'additional-question-1',
                isRequired: true,
                hideButtonBar: true,
                subtitle: <HealthyHabitsStepTitle />,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'need_tuning', image: assets.additional_step_1.eating_cupcake },
                    { label: 'healthy_habits', image: assets.additional_step_1.eating_spaghetti },
                    { label: 'stay_active', image: assets.additional_step_1.eating_ramen },
                ],
            },
            {
                name: 'typical_lunch',
                type: 'select',
                path: 'additional-question-2',
                isRequired: true,
                hideButtonBar: true,
                subtitle: <TypicalLunchStepTitle />,
                completeOnSelect: true,
                choices: ['processed', 'sandwiches_wraps', 'soups_salads', 'home_cooked', 'no_lunch', 'other'],
                inlineImages: [
                    assets.additional_step_2.burger_and_chips,
                    assets.additional_step_2.sandwich,
                    assets.additional_step_2.salad,
                    assets.additional_step_2.ramen,
                    assets.additional_step_2.no_veg,
                    assets.additional_step_2.chef_hat,
                ],
            },
            {
                name: 'meal_frequency',
                type: 'select',
                path: 'additional-question-3',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                choices: ['structured', 'grab', 'snacker', 'variety', 'other'],
            },
            {
                name: 'body_type',
                type: 'custom',
                path: 'your_body_type',
                isRequired: true,
                completeOnSelect: true,
                component: <BodyTypeStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return (answers['body_type'] as string[])?.length > 0 && true
                },
            },
            {
                name: 'processing',
                type: 'custom',
                path: 'processing',
                hideButtonBar: true,
                hideBackHeader: true,
                component: <ProcessingStep />,
            },
            ...[
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'motivation_start',
                        type: 'custom',
                        path: 'motivation_start',
                        component: <LottieTextStep animation={assets.animations.fasty_motivation_start} />,
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'motivation_level',
                        type: 'select',
                        path: 'motivation_level',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: ['consistent', 'medium', 'unsure'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'motivation_confidence',
                        type: 'select',
                        path: 'effect_on_confidence',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: ['mayor', 'minor', 'not_anymore', 'none'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'during_journey',
                        type: 'select',
                        path: 'during_journey',
                        isRequired: true,
                        multiple: true,
                        choices: ['happy', 'peace', 'inspired', 'confident', 'learn', 'other'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'topics_of_interest',
                        type: 'select',
                        path: 'topics_of_interest',
                        isRequired: true,
                        multiple: true,
                        choices: [
                            'nutrition',
                            'mental_health',
                            'habits',
                            'digestion',
                            'sleep',
                            'stress',
                            'physical_activity',
                        ],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'motivation_weight_loss',
                        type: 'select',
                        path: 'motivation_weight_loss',
                        isRequired: true,
                        completeOnSelect: true,
                        choices: ['very', 'confident', 'hesitant', 'doubt'],
                    } as Question),
                ['personalized'].includes(experiements.onboardingVariant) &&
                    ({
                        name: 'motivation_is_key',
                        type: 'custom',
                        path: 'motivation_is_key',
                        component: <LottieTextStep animation={assets.animations.fasty_journey_begins} />,
                    } as Question),
            ].filter(e => e),
            {
                name: 'diseases',
                type: 'select',
                path: 'any_medical_conditions',
                isRequired: true,
                multiple: true,
                showNextHeader: true,
                hideBackHeader: true,
                choices: [
                    'no',
                    'anorexia',
                    'bulimia',
                    'diabetes',
                    'high_blood_pressure',
                    'high_cholesterol',
                    'stress_anxiety_depression',
                    'other',
                ],
                choiceGroups: [
                    ['no'],
                    [
                        'anorexia',
                        'bulimia',
                        'diabetes',
                        'high_blood_pressure',
                        'high_cholesterol',
                        'stress_anxiety_depression',
                        'other',
                    ],
                ],
            },
            {
                name: 'drugs',
                type: 'select',
                path: 'do_you_take_any_medication',
                isRequired: true,
                buttonNextTitle: 'common:controls.done',
                multiple: true,
                showNextHeader: true,
                choices: [
                    'not_taking_anything',
                    'vitamins',
                    'hormones',
                    'antibiotics',
                    'antidepressants',
                    'painkillers',
                    'other',
                ],
                choiceGroups: [
                    ['not_taking_anything'],
                    ['vitamins', 'hormones', 'antibiotics', 'antidepressants', 'painkillers', 'other'],
                ],
            },
            ...[
                ['foodscanner'].includes(theme) &&
                    ({
                        name: 'calorie_intake',
                        type: 'select',
                        path: 'calorie_intake',
                        isRequired: true,
                        showNextHeader: true,
                        choices: ['less_1500', 'between_1500_2000', 'between_2000_2500', 'more_2500', 'unknown'],
                    } as Question),
            ].filter(e => e),
            {
                name: 'storytelling',
                type: 'custom',
                path: 'storytelling',
                hideButtonBar: true,
                hideBackHeader: true,
                component: <StorytellingStep />,
            },
            {
                name: 'stats',
                type: 'custom',
                path: 'stats',
                hideBackHeader: true,
                component: <StatsStep />,
            },
            {
                name: 'trust',
                type: 'custom',
                path: 'trust',
                isRequired: false,
                showNextHeader: true,
                component: <TrustStep />,
            },
            {
                name: 'prepare_plan',
                type: 'custom',
                path: 'prepare_plan',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PreparePlanStep />,
            },
            // remove the signup step if disabled
            ...[
                experiements.emailStepVariant !== 'disabled' &&
                    ({
                        name: 'signup',
                        type: 'custom',
                        path: 'your_email',
                        isRequired: true,
                        hideStepper: true,
                        hideButtonBar: true,
                        hideBackHeader: true,
                        component: <EmailStep />,
                        validate: (question: Question, answers: Answers): boolean => {
                            return answers['email'] && validateEmail(answers['email'] as string) === null
                        },
                    } as Question),
            ].filter(e => e),

            {
                name: 'program',
                type: 'custom',
                path: 'your_program',
                isRequired: true,
                hideHeader: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PlanReadyStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: has purchased
                    return false
                },
            },
            {
                name: 'insurance_paywall',
                type: 'custom',
                path: 'your_weightloss_plan',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <InsurancePaywall />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
            },
            {
                name: 'registration_insurance',
                type: 'custom',
                path: 'registration_insurance',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <SignUpInsurance />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'registration',
                type: 'custom',
                path: 'registration',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <RegistrationStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'download',
                type: 'custom',
                path: 'download',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <DownloadStep />,
            },
        ],
    }
}

export default getSurveyConfig

import { Answers, Question, SurveyConfig } from '../../contexts/survey'
import dynamic from 'next/dynamic'
import { SurveyExperiments, SurveyTheme } from '@contexts/survey/survey'
const DownloadStep = dynamic(() => import('./DownloadStep'))
const PlanReadyStep = dynamic(() => import('./PlanReadyStep/PlanReadyStep'))
const RegistrationStep = dynamic(() => import('./RegistrationStep'))
const SignUpInsurance = dynamic(() => import('../insurance/InvoiceSignUp'))
const InsurancePaywall = dynamic(() => import('../insurance/InsurancePaywall'))

const surveyConfigExternal = (theme: SurveyTheme, experiements: SurveyExperiments, answers: Answers): SurveyConfig => {
    return {
        milestones: [0, 2],
        questions: [
            {
                name: 'program',
                type: 'custom',
                path: 'your_program',
                isRequired: true,
                hideHeader: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PlanReadyStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: has purchased
                    return false
                },
            },
            {
                name: 'insurance_paywall',
                type: 'custom',
                path: 'your_weightloss_plan',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <InsurancePaywall />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
            },
            {
                name: 'registration_insurance',
                type: 'custom',
                path: 'registration_insurance',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <SignUpInsurance />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'registration',
                type: 'custom',
                path: 'registration',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <RegistrationStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'download',
                type: 'custom',
                path: 'download',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <DownloadStep />,
            },
        ],
    }
}

export default surveyConfigExternal

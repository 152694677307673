import React, { useEffect } from 'react'
import { Choices } from '../common/Choices'
import { Step } from '../common/Step'
import { SelectQuestion, useQuestion, useSurvey } from '../../contexts/survey'
import { SurveyStateInitialized } from '@contexts/survey/survey'

interface SelectStepProps {
    extendedOnboardingVariant: 'default' | 'app_onboarding'
}

export const SelectStep: React.FC<SelectStepProps> = ({ extendedOnboardingVariant }) => {
    const [question, answers, dispatch] = useQuestion()
    const [surveyState, _] = useSurvey()

    const onChoiceChange = (values: string[]) => {
        if ((question as SelectQuestion).completeOnSelect) {
            if (values.length !== 0) {
                // only change answer if a new selection was made. Don't deselect
                dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: values })
            }
            setTimeout(() => dispatch({ type: 'QUESTION_COMPLETED' }), 250)
        } else {
            dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: values })
        }
    }

    useEffect(() => {
        if (
            (question as SelectQuestion).preselect &&
            (!Array.isArray(answers[question.name]) || (answers[question.name] as string[]).length == 0)
        ) {
            onChoiceChange((question as SelectQuestion).preselect)
        }
    }, [])

    return (
        <Step key={question.path} name={question.name} title={question.title} subtitle={question.subtitle}>
            <Choices
                name={question.name}
                selected={Array.isArray(answers[question.name]) ? (answers[question.name] as string[]) : []}
                multiple={(question as SelectQuestion).multiple}
                options={(question as SelectQuestion).choices}
                inlineImages={(question as SelectQuestion).inlineImages}
                imageOptions={(question as SelectQuestion).imageOptions}
                multiImageOptions={(question as SelectQuestion).multiImageOptions}
                onChange={onChoiceChange}
                labelClassName={(question as SelectQuestion).labelClassName}
                theme={(surveyState as SurveyStateInitialized).theme}
                extendedOnboardingVariant={extendedOnboardingVariant}
            />
        </Step>
    )
}

import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import CheckIcon from '../icons/CheckIcon'

export interface ColumnChoiceOptionProps {
    checked?: boolean
    label: string
    checkbox?: boolean
    onClick?: () => void
    image: string
    style?: CSSProperties
}

export const ColumnChoiceOption: React.FC<ColumnChoiceOptionProps> = ({
    checked = false,
    label,
    checkbox,
    image,
    style,
    onClick,
}) => {
    const wrapperStyles = classNames(
        'flex flex-row items-center w-full px-4 py-3 mb-2 rounded-xl cursor-pointer animate-fade-in-left border-2',
        checked ? 'bg-[#E8FAF2] border-[#00C781]' : 'bg-white border-transparent',
    )

    const imageStyles = classNames('w-10 h-10 mr-4')
    const textStyles = classNames('flex-grow text-left text-sm font-medium')
    const checkboxStyles = classNames(
        'w-4 h-4 flex justify-center items-center ml-2 rounded-full',
        checked ? 'bg-[#00C781]' : 'bg-gray-200',
    )

    return (
        <div onClick={onClick} style={style} className={wrapperStyles}>
            <img className={imageStyles} src={image} alt="" />
            <div className={textStyles}>{label}</div>
            <div className={checkboxStyles}>
                {checked ? <CheckIcon className="w-5 h-5 text-white" /> : <div className="w-5 h-5" />}
            </div>
        </div>
    )
}
